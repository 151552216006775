<template>
  <header>
    <div><img src="../assets/img/logo_avoloi.svg" alt="logo_avoloi" /></div>
  </header>
  <main class="container">
    <div class="left col-12">
      <img class="logo" src="../assets/img/logo_avoloi.svg" alt="logo_avoloi" />
      <img src="../assets/img/illustrationmail.png" class="illu" />
    </div>
    <div class="enter-account col-12">
      <div class="form">
        <p>
          Entrez le code à 6 chiffres <br />
          de votre médiation
        </p>
        <p>Ce code est présent dans le mail que vous avez reçu pour la demande de médiation</p>
        <form>
          <div class="code mb-3">
            <input data-index="0" ref="input-0" @keydown="focusNextOncePopulated($event, 1)" type="text" maxlength="1" v-model="voucher[0]" @keypress="isLetterOrNumber($event)" @paste="onPaste($event)" />
            <input data-index="1" ref="input-1" @keydown="focusNextOncePopulated($event, 1)" type="text" maxlength="1" v-model="voucher[1]" @keypress="isLetterOrNumber($event)" @paste="onPaste($event)" />
            <input data-index="2" ref="input-2" @keydown="focusNextOncePopulated($event, 1)" type="text" maxlength="1" v-model="voucher[2]" @keypress="isLetterOrNumber($event)" @paste="onPaste($event)" />
            <input data-index="3" ref="input-3" @keydown="focusNextOncePopulated($event, 1)" type="text" maxlength="1" v-model="voucher[3]" @keypress="isLetterOrNumber($event)" @paste="onPaste($event)" />
            <input data-index="4" ref="input-4" @keydown="focusNextOncePopulated($event, 1)" type="text" maxlength="1" v-model="voucher[4]" @keypress="isLetterOrNumber($event)" @paste="onPaste($event)" />
            <input data-index="5" ref="input-5" @keydown="focusNextOncePopulated($event, 1)" type="text" maxlength="1" v-model="voucher[5]" @keyup.enter="submit" @keypress="isLetterOrNumber($event)" />
          </div>
        </form>
      </div>
      <a class="btn-vert" @click="submitDemande" style="cursor: pointer;">Valider</a>
      <p class="rose bold" v-if="errorToken">Votre code médiation est erroné</p>
    </div>
  </main>
</template>


<script>
export default {
  name: 'Connexion',
  data() {
    return {
      voucher: new Array(6),
      contactName: new Array(3),
      errorToken: false,
    };
  },
  methods: {
    // Permet de copier-coller le code de connexion
    // onPaste(event) {
    //   var paste = event.clipboardData.getData('text');
    //   this.voucher[0] = paste[0];
    //   this.voucher[1] = paste[1];
    //   this.voucher[2] = paste[2];
    //   this.voucher[3] = paste[3];
    //   this.voucher[4] = paste[4];
    //   this.voucher[5] = paste[5];
    //   const nextElement = this.$refs?.[`input-5`];
    //   nextElement.focus();
    // },
    onPaste(event) {
      const paste = event.clipboardData.getData('text').slice(0, 6);
      for (let i = 0; i < 6; i++) {
        this.voucher[i] = paste[i] || '';
      }
      this.$refs['input-6'].focus();
    },
    // Valider la connexion
    async submitDemande() {
      let voucherToSend = this.voucher.join('');
      this.$axiosConfigInstanceAuth
        .post(`/authorization`, { code: voucherToSend.toUpperCase() })
        .then((response) => {
          localStorage.AVOLOI_MEDIATION_AUTH_TOKEN = response.data.token;
          this.$axiosConfigInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          this.errorToken = false;
        })
        .catch((err) => {
          this.errorToken = true;
          console.log('error auth :\n', err); // DONNE MESSAGE ERREUR "Votre code E-billet est erroné"
        })
        .finally(() => {
          if (localStorage.AVOLOI_MEDIATION_AUTH_TOKEN && !this.errorToken) {
            this.$router.push({ name: 'Accueil' });
          } else {
            this.errorToken = true;
          }
        });
    },

    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },

    // Fonctions pour les inputs de connexion
    focusNextOncePopulated(event, max) {
      if (event.target.value.length === max && event.key != 'Backspace') {
        const nextElement = this.$refs?.[`input-${Number(event.target.dataset.index) + 1}`];
        if (nextElement) nextElement.focus();
      }
      if (event.target.value.length !== max && event.key == 'Backspace') {
        var previousElement = this.$refs?.[`input-${Number(event.target.dataset.index) - 1}`];
        if (previousElement) previousElement.focus();
      }
    },
    isLetterOrNumber(e, isLetter) {
      var condition1;
      if (e.keyCode === 13) {
        for (let i = 0; i < this.voucher.length; i++) {
          if (this.voucher[i] === undefined || this.voucher[i] === null || this.voucher[i] === '') {
            condition1 = false;
            break;
          } else condition1 = true;
        }
        if (condition1) this.submitDemande();
      }
      let char = String.fromCharCode(e.keyCode);
      if (isLetter) {
        if (/^[A-Za-z\r]+$/.test(char)) return true;
        else e.preventDefault();
      } else {
        if (/^[A-Za-z0-9\r]+$/.test(char)) return true;
        else e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-vert {
  &:focus {
    outline: none;
    background-color: #3bae73 !important;
    border: 1px solid #3bae73 !important;
  }
}
</style>
